.login-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 50vh !important;
  }
  
  h1 {
    color: #D10A11  !important;
    font-size: 2.5rem  !important;
    text-align: center  !important;
  }

  h2 {
    font-size: 24px !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
  }
  
  .error-message {
    color: #D10A11 !important;
    margin-bottom: 10px !important;
  }
  
  input {
    width: 150px !important;
    height: 30px !important;
    padding: 8px !important;
    margin-bottom: 10px !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
  }

  .table-cell input[type='checkbox'] {
    width: 30px !important;
  }
  
  .input-error {
    border-color: #D10A11 !important;
  }
  
  .login-button {
    width: 150px !important;
    height: 40px !important;
    background-color: #D10A11 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    font-weight: bold !important;
    text-decoration: none !important;
    text-transform: none !important;
    }
  
.admin-button {
  min-width: 30px !important;
  width: 30px !important;
  height: 30px !important;
  background-color: #D10A11 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-weight: bold !important;
  text-decoration: none !important;
  text-transform: none !important;
  }
    
    .register-link {
    margin-top: 10px !important;
  }
  
  .register-link a {
    color: #D10A11 !important;
    text-decoration: none !important;
    font-weight: bold !important;
  }
  
  .register-link a:hover {
    text-decoration: underline !important;
  }

  .registration-success-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 20px !important;
    margin-top: 50px !important;
    text-align: center !important;
  }
  
  .registration-success-container h2 {
    font-size: 1.8rem !important;
    color: #D10A11 !important;
    margin-bottom: 10px !important;
  }
  
  .registration-success-container p {
    font-size: 1.2rem !important;
    margin-bottom: 10px !important;
  }
  
  .registration-success-container a {
    color: #D10A11 !important;
    text-decoration: none !important;
    font-weight: bold !important;
  }
  
  .registration-success-container a:hover {
    text-decoration: underline !important;
  }
  
  .table-container {
    padding: 0  !important;
  }
  
  .table-cell {
    padding: 2px !important;
    font-size: 1rem !important;
    text-align: center !important;
    cursor: pointer !important;
  }

  .table-cell-header {
    padding: 1px !important;
    cursor: pointer !important;
    text-align: center !important;
/*    font-size: 1rem !important; */
  }

  .table-cell-name {
    padding: 3px !important;
    width: 50% !important;
/*    font-size: 1rem !important; */
}

  .centered-container {
    justify-content: center;
  }

  .details-table-container {
  width: 80% !important;
  max-width: 400px !important;
  margin: 20px auto;
  border: 1px solid #D10A11;
  border-radius: 15px !important;
  padding: 5px !important;
  }

  .details-cell {
    font-size: 1rem !important;
    padding: 3px !important;
    word-wrap: break-word !important; /* Für ältere Browser */
    overflow-wrap: break-word !important;
  }

  body {
    margin: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace !important;
  }
  
  .centeredText {
    text-align: center !important;
  }
  
  /* filterScreen.css */

.filter-container {
  padding: 20px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.filter-container h2 {
  margin-bottom: 10px !important;
}

.filter-radio input[type="radio"] {
  color: #D10A11 !important;
}

.filter-select {
  width: 150px;
}

.filter-container label {
  display: block !important;
  margin-bottom: 5px !important;
}

.filter-container input[type="text"],
.filter-container input[type="checkbox"] {
  margin-bottom: 10px !important;
  color: #D10A11 !important;
}

.filter-container button {
  margin-right: 10px !important;
  background-color: #D10A11;
  color: #fff !important;
  cursor: pointer !important;
}

.details-button {
  background-color: #D10A11 !important;
  color: #fff !important;
  cursor: pointer !important;
  text-transform: none !important;
}

.filter-container button:last-child {
  margin-right: 0 !important;
}

/* navbar.css */

.navbar {
  background-color: #D10A11 !important;
}

.toolbar {
  justify-content: flex-start !important;
}

.logo {
  display: flex !important;
  align-items: center !important;
  margin-right: 10px !important;
  height: 30px !important;
}

.logo img {
  margin-right: 10px !important;
  height: 30px !important;
}

.app-title,
.filter-button {
  text-decoration: none !important;
  text-transform: none !important;
  color: inherit !important;
}

.admin-input {
  width: 70px !important;
}

.filter-input {
  width: 70px !important;
}
/*
.route-table {
  width: 48% !important;
  border: 2px solid #D10A11 !important;
  background-color: #fcecec !important;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin: 2px;
  font-size: '1rem' !important;
}*/

/* Styling für den coolen 3D-Button */
.route-table {
  width: 48% !important;
  margin: 2px;
  padding-left: 3px;
  font-size: 18px;
  background-color: #fcecec !important;
  border: 1px solid #b4b4b4 !important;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Schatten-Effekt */
  transition: transform 0.3s; /* Für den 3D-Effekt bei Hover */
}

.route-table:hover {
  transform: translateY(-2px); /* Hebe den Button leicht an, wenn darüber gehovert wird */
}

.route-table:active {
  transform: translateY(2px); /* Drücke den Button leicht nach unten, wenn er aktiviert ist */
}

.route-table-name {
  width: 80% !important;
  margin: 4px;
}

.route-table-color {
  text-align: right !important;
  vertical-align: top !important;
}

.route-table-difficulty {
  width: 25% !important;
  text-align: center !important;
}

.route-table-trvs {
  text-align: right !important;
}

.admin-container {
  width: 90% !important;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
}

.admin-table-cell {
  padding: 5px !important;
  font-size: 1.25rem !important;
  text-align: center !important;
}

.admin-table-cell-left {
  padding: 5px !important;
  font-size: 1.25rem !important;
  text-align: left !important;
}

.checkin-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.checkin-table {
  border: 2px solid #D10A11 !important;
  border-radius: 15px !important;
}

.checkin-table-cell {
  padding: 5px !important;
}

.checkin-table-header {
  padding: 5px !important;
  background-color: #b4b4b4;
}

.checkin-container h1 {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.checkin-container p {
  margin: 8px 0;
}

.checkin-container label {
  display: block;
  margin-bottom: 8px;
}

.checkin-container input[type='email'],
.checkin-container input[type='password'] {
    width: 150px !important;
  padding: 3px !important;
  margin-left: 3px !important;
  border: 0px solid #ccc !important;
  border-radius: 4px !important;
}
.checkin-container input[type='text'],
.checkin-container input[type='date'] {
  width: 150px !important;
  padding: 3px !important;
  margin-left: 3px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

.checkin-container input[type='checkbox'],
.checkin-container input[type='radio'] {
  width: 20px !important;
  padding: 0px !important;
  margin-left: 3px !important;
  vertical-align: middle;
}

.checkin-container input[type='radio'] {
  margin-left: 20px !important;
}

.checkin-container input[type='checkbox'] {
  margin-right: 8px;
}

.checkin-button {
  padding: 8px 16px;
  background-color: #D10A11;
  margin-bottom: 10px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.checkin-success {
  color: green;
}

.checkin-success-div {
  text-align: center;
  border: 1px solid green;
  margin: 15px;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 0 10px green (0, 0, 0, 0.2);
}

.checkin-error {
  color: #D10A11;
}

.checkin-register-link {
  margin-top: 25px !important;
  text-align: center;
}

/* Stile für die Fehleranzeige bei Pflichtfeldern */
.checkin-container input.input-error {
  border-color: #D10A11;
}

/* start.css */

.start-container {
  display: flex;
  flex-direction: column;
  align-items: center;
/*  height: 100vh; */
}

.tile-container {
  display: grid;
  grid-gap: 20px;
  margin-top: 30px;
}

@media (min-width: 0px) {
  .tile-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 488px) {
  .tile-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 650px) {
  .tile-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 813px) {
  .tile-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

.tile-container h2 {
  text-align: center !important;
}

.tile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 80px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  transition: transform 0.2s ease;
}

.tile:hover {
  transform: scale(1.05);
}

.back-arrow {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}